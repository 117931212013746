<template>
  <v-dialog transition="dialog-top-transition" v-model="dialog" width="640px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="rounded-lg text-capitalize black--text w-100"
        v-bind="attrs"
        v-on="on"
        color="secondary"
      >
        <v-icon class="mr-2">mdi-account-plus</v-icon>
        Agregar Visitante
      </v-btn>
    </template>
    <div class="row mx-0 my-0">
      <div class="col bg-white py-2" v-if="validQR">Escanear QR</div>
      <div class="col bg-white py-2 red--text" v-else>No es un QR valido</div>
    </div>
    <div>
      <StreamBarcodeReader @decode="onDecode" class="mt-2">
      </StreamBarcodeReader>
    </div>
    <div class="row mx-0 my-0">
      <v-btn @click="dialog = false" elevation="2" class="w-100"
        >Cancelar</v-btn
      >
    </div>
  </v-dialog>
</template>

<style scoped>
.w-100 {
  min-width: 100% !important;
  width: 100% !important;
}

.bg-white {
  background-color: white;
}
</style>

<script>
import notificationMixin from "@/mixins/notificationMixin";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: "LoadQr",
  components: { StreamBarcodeReader },
  mixins: [notificationMixin],
  data() {
    return {
      dialog: false,
      destroyedQr: false,
      validQR: true,
    };
  },
  methods: {
    isValid(visitor) {
      return visitor.attendeeUuid && visitor.fullName;
    },
    async onDecode(result) {
      console.log(result);
      if (result) {
        console.log("Inicia proceso QR");
        let data = result.split("|");
        if (data.length != 5) {
          this.validQR = false;
          return;
        }

        let companyPosition = data[3].split("-");
        let visitor = {
          companyName: companyPosition[0] ?? null,
          attendeeUuid: data[0],
          fullName: data[1],
          companyPosition: companyPosition[1] ?? companyPosition[0] ?? null,
          phone: data[4],
          email: data[2],
        };

        if (!this.isValid(visitor)) {
          this.showError("Datos del visitante incompletos");
          this.destroyedQr = true;
          return;
        }

        let isVisitorDuplicated = await this.$store.dispatch(
          "visitors/isVisitorDuplicated",
          visitor
        );
        if (isVisitorDuplicated) {
          this.showWarning("El visitante ya ha sido registrado");
          this.destroyedQr = true;
          return;
        }

        this.$emit("add-temp-visitor", visitor);
        this.validQR = true;
        this.dialog = false;
      }
    },
    async reload() {
      this.destroyedQr = true;

      await this.$nextTick();

      this.destroyedQr = false;
    },
  },
  watch: {
    dialog(value) {
      if (!value) this.reload();
    },
  },
};
</script>
